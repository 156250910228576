import { VUE_APP_ROOT_API_KDI, VUE_APP_ROOT_API_TURING } from './config';
const KDI_PREFIX = VUE_APP_ROOT_API_KDI;
const TURING_PREFIX = VUE_APP_ROOT_API_TURING;
const AUTH_PREFIX = VUE_APP_ROOT_API_KDI;

export const routes = {
    auth: {
        login: () => `${AUTH_PREFIX}/auth/login`,
        remember: () => `${AUTH_PREFIX}/auth/remember`
    },
    kdi: {
        public: {
            installPointsAll: () => `${KDI_PREFIX}/public/install_points/all`,
            installPointsFilter: () => `${KDI_PREFIX}/public/install_points/filter`,
            devicesInstallPoints: () => `${KDI_PREFIX}/public/devices-install-points`,
        },
        changePassword: () => `${KDI_PREFIX}/users/change-password`,
        users: () => `${KDI_PREFIX}/users`,
        usersNew: () => `${KDI_PREFIX}/users/new`,
        usersAll: () => `${KDI_PREFIX}/users/all`,
        usersFilter: () => `${KDI_PREFIX}/users/filter`,
        devices: () => `${KDI_PREFIX}/devices`,
        devicesAll: () => `${KDI_PREFIX}/devices/all`,
        devicesFilter: () => `${KDI_PREFIX}/devices/filter`,
        clients: () => `${KDI_PREFIX}/clients`,
        clientsAll: () => `${KDI_PREFIX}/clients/all`,
        clientsFilter: () => `${KDI_PREFIX}/clients/filter`,
        managerCommission: () => `${KDI_PREFIX}/manager/commission`,
        meterDeviceInstallPointAll: () => `${KDI_PREFIX}/meter_device_install_point/all`,
        meterDeviceInstallPointFilterClient: () => `${KDI_PREFIX}/meter_device_install_point/filter-client`,
        meterDeviceInstallPoint: () => `${KDI_PREFIX}/meter_device_install_point`,
        installPointsTags: () => `${KDI_PREFIX}/install_points`,
        deviceSchemaFilter: () => `${KDI_PREFIX}/device-schema/filter`,
        tags: () => `${KDI_PREFIX}/tags`,
        tagsFilter: () => `${KDI_PREFIX}/tags/filter`,
        devicesMigration: () => `${KDI_PREFIX}/devices/migration`,
        meterDeviceInstallPointFilter: () => `${KDI_PREFIX}/meter_device_install_point/filter`,
        connectivityDataFilterCommunicationHistory: () => `${KDI_PREFIX}/connectivity_data/filter/communication_history`,
        event_dataFilterEventLogs: () => `${KDI_PREFIX}/event_data/filter/event_logs`,

    },
    turing: {
        public: {
            readings: () => `${TURING_PREFIX}/public/readings`,
            readingsAll: () => `${TURING_PREFIX}/public/readings/all`,
        },
        countAlarms: () => `${TURING_PREFIX}/device-state/count/alarms`,
        reportReadingsAll: () => `${TURING_PREFIX}/report/readings/all`,
        devices_dataCalcAverageFlowHour: () => `${TURING_PREFIX}/devices_data/calc/average-flow-hour`,
        devices_dataCalcAverageFlowPerHour: () => `${TURING_PREFIX}/devices_data/calc/average-flow-per-hour`,
        devices_dataCalcAccumulatedFlow: () => `${TURING_PREFIX}/devices_data/calc/accumulated-flow`,
    },
};